import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '32, 47, 84',
		'primary-dark': '50, 110, 175',
		'accent': '239, 191, 143',
		'accent-plus': '0, 0, 0',
	},
});
